import React, { useState } from 'react'

const blogsData = [
  {
    id: 1,
    title: 'Blog Title 1',
    author: 'John Doe',
    content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et ultricies nunc. Quisque congue nunc sed quam ullamcorper, in eleifend odio aliquam. Integer vel elit ligula. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Duis eget ultrices velit.'
  },
  {
    id: 2,
    title: 'Blog Title 2',
    author: 'Jane Smith',
    content: 'Sed ut dui vel est varius egestas. Aliquam fringilla neque id consectetur lacinia. Nam gravida augue ac purus sollicitudin, vitae molestie ante bibendum. Phasellus nec enim a erat laoreet ultricies et in nulla. In a enim sit amet est consequat fringilla. Nullam mattis mi vel sem aliquet, nec semper eros aliquam.'
  },
  {
    id: 3,
    title: 'Blog Title 3',
    author: 'David Brown',
    content: 'Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Fusce tincidunt commodo elit vitae vulputate. Vivamus eu dolor felis. Nam accumsan, leo id scelerisque volutpat, nisl leo laoreet sem, eu viverra nulla ex vitae dui. Curabitur a sem vitae felis condimentum fermentum. Maecenas pulvinar ex et tellus lobortis vestibulum.'
  },
  {
    id: 4,
    title: 'Blog Title 4',
    author: 'Emily Johnson',
    content: 'Vivamus ullamcorper, risus ac viverra lobortis, sem neque viverra augue, in consequat metus mauris at purus. Proin auctor sapien vitae nibh volutpat, sed malesuada turpis ultricies. Aliquam pretium tortor eu felis luctus, nec auctor lectus molestie. In id dolor in mi molestie rutrum. Fusce nec convallis lacus.'
  },
  {
    id: 5,
    title: 'Blog Title 5',
    author: 'Michael Williams',
    content: 'Integer feugiat commodo purus nec consequat. Proin vehicula, justo eget fermentum lobortis, lacus velit viverra dolor, vel facilisis mauris est ut dolor. In in condimentum magna. In hac habitasse platea dictumst. Duis ut suscipit justo, non ultrices sapien. Sed laoreet enim eu orci maximus vestibulum.'
  },
  {
    id: 6,
    title: 'Blog Title 6',
    author: 'Emma Garcia',
    content: 'Quisque malesuada massa eu fermentum auctor. Proin suscipit nunc ut ex sollicitudin, non fringilla urna laoreet. Fusce nec ullamcorper velit. Sed tincidunt fringilla libero, non scelerisque urna bibendum ut. Nullam lobortis pretium purus. Sed ut malesuada quam.'
  },
  {
    id: 7,
    title: 'Blog Title 7',
    author: 'William Brown',
    content: 'Phasellus sit amet justo ac nisi ultricies tempus vel id felis. Praesent vestibulum pharetra erat sit amet placerat. Nulla consequat velit at tellus interdum, nec dapibus dolor facilisis. Nulla facilisi. Aenean convallis magna at turpis lobortis, id vehicula elit fermentum. Suspendisse et aliquet est.'
  },
  {
    id: 8,
    title: 'Blog Title 8',
    author: 'Olivia Taylor',
    content: 'Vestibulum a nunc vel quam venenatis sollicitudin et eget mauris. Proin vel ipsum nec elit aliquet vehicula. Aliquam quis libero quis odio malesuada dapibus. Sed sed odio vel mauris commodo sodales eu ut lorem. Suspendisse potenti.'
  },
  {
    id: 9,
    title: 'Blog Title 9',
    author: 'Daniel Martinez',
    content: 'Donec sollicitudin ex nec ante gravida, nec eleifend elit fermentum. Nam euismod justo id felis lacinia, non hendrerit odio fermentum. Sed nec diam eget odio efficitur facilisis in sit amet odio. Vivamus eleifend mi eu arcu efficitur lobortis.'
  },
  {
    id: 10,
    title: 'Blog Title 10',
    author: 'Sophia Rodriguez',
    content: 'Maecenas sollicitudin, justo nec posuere mattis, purus justo ultrices metus, vel aliquam dui arcu et est. Integer quis nunc sit amet purus dictum rhoncus ac vel purus. Maecenas sodales, lorem vel suscipit suscipit, lectus ex condimentum ipsum, non venenatis quam ex ut lorem. Sed eu scelerisque est.'
  },
];



const Blogs = () => {
  const [selectedBlog, setSelectedBlog] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleBlogClick = (blog) => {
    setSelectedBlog(blog);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedBlog(null);
    setIsModalOpen(false);
  };

  return (
    <div className="bg-gray-100 min-h-screen bg-gradient-to-r from-cyan-500 to-blue-500">
      <main className="container mx-auto px-4 py-8 w-[90%] flex flex-col">
        <h1 className="text-3xl md:text-5xl font-bold mb-8">Blogs</h1>
        
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {blogsData.map((blog) => (
            <div key={blog.id} className="bg-white shadow-md p-4 rounded-lg cursor-pointer border" onClick={() => handleBlogClick(blog)}>
              <h2 className="text-xl font-bold mb-2">{blog.title}</h2>
              <p className="text-gray-600">Author: {blog.author}</p>
            </div>
          ))}
        </div>

        {selectedBlog && (
          <div className={`fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 ${isModalOpen ? '' : 'hidden'}`}>
            <div className="bg-white p-8 rounded-lg w-full md:w-2/3 lg:w-1/2 max-w-lg">
              <div className="flex justify-end">
                <button className="text-gray-500 hover:text-gray-800" onClick={closeModal}>
                  <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
              <h1 className="text-3xl text-center font-bold mb-4">Selected Article</h1>
              <h2 className="text-2xl font-bold mb-4">{selectedBlog.title}</h2>
              <p className="text-gray-600 mb-4">Author: {selectedBlog.author}</p>
              <p>{selectedBlog.content}</p>
            </div>
          </div>
        )}
      </main>
    </div>
  );
}

export default Blogs;