import React from 'react'
import internshipimg from '../images/internship.png'
const Internship = () => {
  const internships = [
    {
      id: 1,
      title: 'Software Development Internship',
      duration: 'Jan-July 2024',
      description: 'Gain hands-on experience in developing robust and scalable applications.',
    },
    {
      id: 2,
      title: 'Data Science Internship',
      duration: 'Jan-July 2024',
      description: 'Work on real-world data projects and gain insights into data analytics and reporting.',
    },
    {
      id: 3,
      title: 'UI/UX Design Internship',
      duration: 'Jan-July 2024',
      description: 'Create intuitive and visually appealing user interfaces and experiences.',
    },
    {
      id: 4,
      title: 'Marketing Internship',
      duration: 'Jan-July 2024',
      description: 'Assist in developing and implementing marketing strategies to promote products or services.',
    },
    {
      id: 5,
      title: 'Business Development Internship',
      duration: 'Jan-July 2024',
      description: 'Explore new business opportunities and assist in developing growth strategies.',
    },

  ];


  return (
    <div className="bg-gray-100 min-h-screen">
      <div className="container mx-auto px-4 py-8 flex flex-col md:flex-row">
        <div className="md:w-1/2 md:pr-8">
          <h1 className="text-3xl md:text-5xl font-bold mb-8">Internship Opportunities</h1>
          {internships.map(internship => (
            <div key={internship.id} className="bg-white shadow-md p-6 rounded-lg mb-6">
              <h2 className="text-xl font-bold mb-2">{internship.title}</h2>
              <p className="text-gray-600 mb-2">{internship.duration}</p>
              <p className="text-gray-700">{internship.description}</p>
            </div>
          ))}
        </div>
        <div className="md:w-1/2 md:pl-8">
          <img src={internshipimg} alt="" className='rounded-xl'/>
        </div>
      </div>
    </div>
  )
}

export default Internship