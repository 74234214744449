import React from 'react'

const ShowCase = () => {
  return (
    <div className="bg-gray-100 min-h-screen py-12">
      <div className="container mx-auto w-[90%]">
        <h1 className="text-4xl font-bold text-center mb-8">Our Showcase</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          <div className="bg-white rounded-lg shadow-md p-6">
            <h2 className="text-xl font-semibold mb-4">Project 1</h2>
            <p className="text-gray-700">Description of Project 1 goes here...</p>
            <img src="https://via.placeholder.com/400" alt="Project 1" className="mt-4 rounded-md" />
          </div>
          <div className="bg-white rounded-lg shadow-md p-6">
            <h2 className="text-xl font-semibold mb-4">Project 2</h2>
            <p className="text-gray-700">Description of Project 2 goes here...</p>
            <img src="https://via.placeholder.com/400" alt="Project 2" className="mt-4 rounded-md" />
          </div>
          <div className="bg-white rounded-lg shadow-md p-6">
            <h2 className="text-xl font-semibold mb-4">Project 3</h2>
            <p className="text-gray-700">Description of Project 3 goes here...</p>
            <img src="https://via.placeholder.com/400" alt="Project 3" className="mt-4 rounded-md" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ShowCase