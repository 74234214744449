import React from 'react'
import innovation from '../images/innovation.jpg'
const Hero = () => {
  return (
    <div className="bg-gradient-to-r from-cyan-500 to-blue-500 min-h-screen text-white">
    <main className="container mx-auto px-4 py-8 flex flex-col justify-center w-[90%]">
        <section className="flex flex-col md:flex-row items-center justify-center md:justify-between">
          <div className="md:w-1/2">
            <h1 className="text-4xl md:text-6xl font-bold leading-tight text-center md:text-left mb-4 md:mb-0">
              Welcome to IgnAite
            </h1>
            <p className="text-lg text-center md:text-left">
              We're dedicated to fueling innovation and transforming ideas into reality.
            </p>
          </div>
          <div className="md:w-1/2 mt-8 md:mt-0">
            <img
              src={innovation}
              alt="Innovation"
              className="rounded-lg shadow-lg"
            />
          </div>
        </section>

        <section className="my-12 flex flex-col">
          <h2 className="text-2xl font-bold mb-4">Internship Opportunities</h2>
          <p className="text-lg">
            Are you a student looking to gain valuable experience and contribute to innovative
            projects? IgnAite offers exciting internship opportunities for aspiring individuals.
            Join us and ignite your career!
          </p>
        </section>

        <section className="my-12">
          <h2 className="text-2xl font-bold mb-4">Why Choose IgnAite?</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="border  rounded-lg shadow-lg p-6">
              <h3 className="text-xl font-semibold mb-2 text-white">Innovative Projects</h3>
              <p className=" text-white">
                At IgnAite, you'll have the opportunity to work on innovative projects that
                challenge and inspire you.
              </p>
            </div>
            <div className=" border rounded-lg shadow-lg p-6">
              <h3 className="text-xl font-semibold mb-2 text-white">Hands-on Experience</h3>
              <p className="text-white">
                Gain practical, hands-on experience in a real-world startup environment under the
                guidance of industry experts.
              </p>
            </div>
            <div className="border  rounded-lg shadow-lg p-6">
              <h3 className="text-xl font-semibold mb-2 text-white">Mentorship</h3>
              <p className="text-white">
                Benefit from mentorship and guidance from experienced professionals to help you
                succeed in your internship.
              </p>
            </div>
            <div className="border  rounded-lg shadow-lg p-6">
              <h3 className="text-xl font-semibold mb-2 text-white">Networking Opportunities</h3>
              <p className="text-white">
                Connect with like-minded individuals and expand your professional network through
                various networking opportunities.
              </p>
            </div>
          </div>
        </section>

        <section className="my-12">
          <h2 className="text-2xl font-bold mb-4">Our Partners</h2>
          {/* <div className="flex justify-center items-center">
            <img
              src="/images/partners.jpg"
              alt="Partners"
              className="rounded-lg shadow-lg"
            />
          </div> */}
        </section>
      </main>
      </div>
  )
}

export default Hero