import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navbarRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header className="bg-black text-white py-6 px-8 sticky top-0 z-10">
      <div className="container mx-auto flex justify-between items-center">
        <h1 className="text-xl font-bold">IgnAite</h1>
        <nav className="md:block hidden">
          <ul className="flex space-x-2 text-lg">
            <li><Link to="/" className='mx-4'>Home</Link></li>
            <li><Link to="/blogs"className='mx-4'>Blogs</Link></li>
            <li><Link to="/aboutus" className='mx-4'>About us</Link></li>
            <li><Link to="/showcase" className='mx-4'>Show Case</Link></li>
            <li><Link to="/contact" className='mx-4'>Contact</Link></li>
            <li><Link to="/internship" className='mx-4 bg-orange-400 text-white p-2 rounded'>Internship</Link></li>
          </ul>
        </nav>
        <button className="md:hidden block text-white focus:outline-none" onClick={toggleMenu} aria-label="Toggle Menu">
          {isOpen ? (
            <svg className="w-8 h-8 transition-transform transform rotate-90" viewBox="0 0 24 24" fill="none" stroke="white">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
            </svg>
          ) : (
            <svg className="w-8 h-8 transition-transform transform rotate-0" viewBox="0 0 24 24" fill="none" stroke="white">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path>
            </svg>
          )}
        </button>
      </div>
      <nav ref={navbarRef} className={`md:hidden transition-all duration-500 ease-in-out overflow-hidden ${isOpen ? 'max-h-screen' : 'max-h-0'}`}>
        <ul className="flex flex-col space-y-4">
          <li><Link to="/" className='block text-center' onClick={toggleMenu}>Home</Link></li>
          <li><Link to="/blogs"className='block text-center' onClick={toggleMenu}>Blogs</Link></li>
          <li><Link to="/aboutus" className='block text-center' onClick={toggleMenu}>About us</Link></li>
          <li><Link to="/showcase" className='block text-center' onClick={toggleMenu}>Show Case</Link></li>
          <li><Link to="/contact" className='block text-center' onClick={toggleMenu}>Contact</Link></li>
          <li><Link to="/internship" className='block bg-orange-400 text-white p-2 rounded text-center' onClick={toggleMenu}>Internship</Link></li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
