import { Route, Routes } from "react-router-dom";
import Header from "./components/Header";

import Home from "./Pages/Home";
import Blogs from "./Pages/Blogs";
import Aboutus from "./Pages/AboutUs";
import ShowCase from "./Pages/ShowCase";
import Contact from "./Pages/Contact";
import Internship from "./Pages/Internship";

function App() {
  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/aboutus" element={<Aboutus />} />
        <Route path="/showcase" element={<ShowCase />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/internship" element={<Internship />} />
      </Routes>
    </div>
  );
}

export default App;
