import React from 'react'
import innovationImage from '../images/innovation.jpg';
import softwareImage from '../images/Software-Development.jpg';
const AboutUs = () => {
  return (
    <div className='bg-gradient-to-r from-cyan-500 to-blue-500 min-h-screen text-white'>
      <main className="container mx-auto px-4 py-8 w-[90%] flex flex-col justify-center">
        <section className="mb-12">
          <h1 className="text-3xl md:text-5xl font-bold mb-4">About Us</h1>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className='flex flex-col justify-center'>
              <h2 className="text-2xl font-bold mb-2">What Sets Us Apart:</h2>
              <ul className="list-disc list-inside">
                <li className="mb-2"><b>Innovation Hub:</b> Our workspace is a breeding ground for creativity and forward-thinking.</li>
                <li className="mb-2"><b>Tailored Solutions:</b> We understand that one size doesn’t fit all. Our solutions are customized to meet your unique needs.</li>
                <li><b>Customer-Centric Approach:</b> Your success is our priority. We work collaboratively to ensure your goals are not just met but exceeded.</li>
              </ul>
            </div>
            <div>
              <img src={innovationImage} alt="Innovation" className="w-[80%] h-auto rounded-2xl shadow-md border border-gray-300" />
            </div>
          </div>
        </section>

        <section className="mb-12">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <img src={softwareImage} alt="Software Development" className="w-[80%] h-auto rounded-lg shadow-2xl border border-gray-300" />
            </div>
            <div className='flex flex-col justify-center'>
              <h2 className="text-2xl font-bold mb-2">Our Services:</h2>
              <ul className="list-disc list-inside">
                <li className="mb-2"><b>Software Development:</b> Crafting robust and scalable applications tailored to your business requirements.</li>
                <li className="mb-2"><b>AI Solutions:</b> Harnessing the power of artificial intelligence to drive efficiency and enhance decision-making.</li>
                <li><b>IoT Integration:</b> Connecting the dots in the digital landscape, creating smart, interconnected systems.</li>
              </ul>
            </div>
          </div>
        </section>

        <section className="mb-12">
          <h2 className="text-2xl font-bold mb-4">Why Choose IgnAite:</h2>
          <ul className="list-disc list-inside">
            <li className="mb-2"><b>Proven Track Record:</b> Years of successful projects and satisfied clients speak to our expertise.</li>
            <li className="mb-2"><b>Forward-Thinking Team:</b> We thrive on challenges, embracing the latest technologies to keep you ahead of the curve.</li>
            <li><b>Reliable Support:</b> Our commitment to your success extends beyond project completion. We’re with you every step of the way.</li>
          </ul>
        </section>
      </main>
    </div>
  )
}

export default AboutUs